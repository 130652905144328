export const ORDER_BY_TYPE = {
  LectureLowPrice: 'LECTURE_LOW_PRICE',
  LectureLowLevel: 'LECTURE_LOW_LEVEL',
  LectureHighLevel: 'LECTURE_HIGH_LEVEL',
  LectureReviewCnt: 'LECTURE_REVIEW_CNT',
  LectureStudentCnt: 'LECTURE_STUDENT_CNT',
} as const;

export const SEARCH_TYPE = {
  LectureAll: 'LECTURE_ALL',
  LectureName: 'LECTURE_NAME',
  LectureTeacher: 'LECTURE_TEACHER',
} as const;

export const FILTER_KEY_TYPE = {
  LectureOriginList: 'lectureOriginList',
  LevelList: 'levelList',
  PriceType: 'priceType',
} as const;

export const LEARNMOA_TYPE = {
  Lecture: 'lecture',
  Book: 'book',
} as const;

export const BOOK_SEARCH_TYPE = {
  All: 'BOOK_ALL',
  Name: 'BOOK_NAME',
  Teacher: 'BOOK_TEACHER',
} as const;

export const REFERENECE_TYPE = {
  Blog: 'BLOG',
  Github: 'GITHUB',
  Linkedin: 'LINKEDIN',
} as const;

export const CONTENT_TYPE = {
  Lecture: 'LECTURE',
  Book: 'BOOK',
  LearnPack: 'LEARNPACK',
};

export const LEARNPACK_CONTENT_TYPE = {
  Lecture: 'LECTURE',
  Book: 'BOOK',
  ExternalLink: 'EXTERNAL_LINK',
};

export const MYPAGE_MENU_TYPE = {
  Bookmark: 'BOOKMARK',
  MyLearnPack: 'MY_LEARN_PACK',
};

export type enumType<T> = T[keyof T];

export type ORDER_BY_TYPE = enumType<typeof ORDER_BY_TYPE>;
export type SEARCH_TYPE = enumType<typeof SEARCH_TYPE>;
export type FILTER_KEY_TYPE = enumType<typeof FILTER_KEY_TYPE>;
export type LEARNMOA_TYPE = enumType<typeof LEARNMOA_TYPE>;
export type BOOK_SEARCH_TYPE = enumType<typeof BOOK_SEARCH_TYPE>;
export type REFERENECE_TYPE = enumType<typeof REFERENECE_TYPE>;
export type CONTENT_TYPE = enumType<typeof CONTENT_TYPE>;
export type LEARNPACK_CONTENT_TYPE = enumType<typeof LEARNPACK_CONTENT_TYPE>;
export type MYPAGE_MENU_TYPE = enumType<typeof MYPAGE_MENU_TYPE>;
