import { computed, getCurrentInstance, WritableComputedRef } from '@vue/composition-api';

export const comma = (value: number): string | number => {
  if (value) {
    return value.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }

  return 0;
};

export function propSync<T extends Record<string, unknown>, K extends keyof T>(
  props: T,
  key: K,
): WritableComputedRef<T[K]> {
  const vm = getCurrentInstance();
  if (!vm) {
    throw new Error('getCurrentInstance는 setup이나 life cycle hooks에서만 동작합니다.');
  }

  return computed({
    get() {
      return props[key];
    },
    set(value) {
      vm.emit(`update:${String(key)}`, value);
    },
  });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function normalizeParams<T>(params: { [key: string]: unknown }) {
  return Object.keys(params).reduce((acc, key) => {
    if (!params[key] || params[key] === undefined || params[key] === null || params[key] === '') {
      return {
        ...acc,
      };
    }
    return {
      ...acc,
      [key]: params[key],
    };
  }, {} as T);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isProduction() {
  return process.env.NODE_ENV !== 'development';
}
