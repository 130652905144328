import { HTTPClient } from '@/api/HTTPClient';
import { IUserDetail, IUserMeta } from '@/models';

export interface IAuthService {
  register({
    nickName,
    email,
    password,
    position,
    introduction,
    referenceUrls,
    profileUrl,
  }: {
    nickName: string;
    email: string;
    password: string;
    position?: string;
    introduction?: string;
    referenceUrls?: {
      type: string;
      url: string;
    }[];
    profileUrl?: string;
  }): Promise<void>;

  emailValidate(email: string): Promise<void>;

  emailValidateConfirm({ email, code }: { email: string; code: string }): Promise<void>;

  login({ email, password }: { email: string; password: string }): Promise<string>;

  getDetail(): Promise<IUserDetail>;

  meta(): Promise<IUserMeta>;

  resetPassword(email: string): Promise<void>;

  updatePassword(password: string): Promise<void>;

  updateUser({
    nickName,
    introduction,
    position,
    referenceUrls,
    profileUrl,
  }: {
    nickName: string;
    introduction: string;
    position: string;
    referenceUrls: {
      type: string;
      url: string;
    }[];
    profileUrl: string;
  }): Promise<void>;
}

class AuthSearvice extends HTTPClient implements IAuthService {
  async register({
    nickName,
    email,
    password,
    position,
    introduction,
    referenceUrls,
    profileUrl,
  }: Parameters<IAuthService['register']>[0]) {
    const response = await this.http.post('/users', {
      nickName,
      email,
      password,
      ...(position && {
        position,
      }),
      ...(introduction && {
        introduction,
      }),
      ...(referenceUrls && {
        referenceUrls,
      }),
      ...(profileUrl && {
        profileUrl,
      }),
    });
    return response.data;
  }

  async emailValidate(email: Parameters<IAuthService['emailValidate']>[0]) {
    const response = await this.http.post('/email-validate', { email });
    return response.data;
  }

  async emailValidateConfirm({ email, code }: Parameters<IAuthService['emailValidateConfirm']>[0]) {
    const response = await this.http.put('/email-validate', { email, validationStr: code });
    return response.data;
  }

  async login({ email, password }: Parameters<IAuthService['login']>[0]) {
    const response = await this.http.post('/users/login', {
      email,
      password,
    });
    return response.data;
  }

  async getDetail(): ReturnType<IAuthService['getDetail']> {
    const response = await this.http.get('/users/detail');
    return response.data;
  }

  async meta() {
    const response = await this.http.get('/auth');
    return response.data;
  }

  async resetPassword(email: Parameters<IAuthService['resetPassword']>[0]) {
    const response = await this.http.put('/users/password/init', {
      email,
    });
    return response.data;
  }

  async updatePassword(password: Parameters<IAuthService['resetPassword']>[0]) {
    const response = await this.http.put('/users/password', {
      password,
    });
    return response.data;
  }

  async updateUser({
    nickName,
    introduction,
    position,
    referenceUrls,
    profileUrl,
  }: Parameters<IAuthService['updateUser']>[0]) {
    const response = await this.http.put('/users', {
      nickname: nickName,
      introduction,
      position,
      referenceUrls,
      profileUrl,
    });
    return response.data;
  }
}

export const authSearvice = new AuthSearvice('');
