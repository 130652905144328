import { getToken, removeToken } from '@/utils/token';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import queryString from 'query-string';

function handleRequest(config: AxiosRequestConfig) {
  const token = getToken();
  if (token) {
    config.headers.Authorization = token;
  }

  return config;
}

function handleRequestResponse(error: AxiosError) {
  return Promise.reject(error);
}

function handleResponse(response: AxiosResponse) {
  return response;
}

function handleResponseError(error: AxiosError) {
  const result = Promise.reject(error);

  if (error.response?.status === 401) {
    alert('인증 정보가 없습니다. 다시 로그인을 진행해 주세요.');
    removeToken();
    window.location.reload();
    return result;
  }

  if (error.response?.data.message) {
    alert(error.response.data.message);
    return result;
  }

  return result;
}
export class HTTPClient {
  protected http: AxiosInstance;

  protected interceptors: {
    request?: number;
    response?: number;
  } = {};

  constructor(serviceURL = '') {
    this.http = axios.create({
      baseURL: `${process.env.VUE_APP_API_HOST}/${serviceURL}/`,
      paramsSerializer(params) {
        return queryString.stringify(params, { arrayFormat: 'none' });
      },
    });

    this.interceptors.request = this.http.interceptors.request.use(
      handleRequest,
      handleRequestResponse,
    );
    this.interceptors.response = this.http.interceptors.response.use(
      handleResponse,
      handleResponseError,
    );
  }
}
