import { ref, onUnmounted, onMounted } from '@vue/composition-api';

export const MOBILE = 'MOBILE';
export const TABLET = 'TABLET';
export const DESKTOP = 'DESKTOP';

type TDeviceType = 'MOBILE' | 'DESKTOP';

const mobileWidth = 768;

function getDevice(width: number) {
  if (width <= mobileWidth) {
    return MOBILE;
  }

  return DESKTOP;
}

export const useViewport = () => {
  const device = ref<TDeviceType>(getDevice(window.innerWidth));

  const handleResize = () => {
    device.value = getDevice(window.innerWidth);
  };

  onMounted(() => {
    window.addEventListener('resize', handleResize);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', handleResize);
  });

  return {
    device,
  };
};
