import { HTTPClient } from '@/api/HTTPClient';

import { IBook, ILearnPack, ILearnPackContent, ILearnPackExternalLink, ILecture } from '@/models';

import { CONTENT_TYPE } from '@/utils';

export interface ILearnPackService {
  search({
    contentType,
    keyword,
    page,
  }: {
    page: number;
    contentType: CONTENT_TYPE;
    keyword: string;
  }): Promise<{
    content: (ILecture | IBook)[];
    last: boolean;
  }>;
  openGraph(link: string): Promise<ILearnPackExternalLink>;
  create({
    title,
    introduction,
    tags,
    learnPackElements,
  }: {
    title: string;
    introduction: string;
    tags: string[];
    learnPackElements: ILearnPackContent[];
  }): Promise<number>;

  update({
    learnPackId,
    title,
    introduction,
    tags,
    learnPackElements,
  }: {
    learnPackId: number;
    title: string;
    introduction: string;
    tags: string[];
    learnPackElements: ILearnPackContent[];
  }): Promise<void>;

  delete(learnPackId: number): Promise<void>;

  getDetail(id: number): Promise<ILearnPack>;
  getMyDetail(id: number): Promise<ILearnPack>;

  getLearnPackList({ page, size }: { page: number; size: number }): Promise<{
    content: ILearnPack[];
    totalElements: number;
  }>;

  getMyLearnPackList({ page, size }: { page: number; size: number }): Promise<{
    content: ILearnPack[];
    totalElements: number;
    last: boolean;
  }>;
}

class LearnPackSearvice extends HTTPClient implements ILearnPackService {
  async search({ contentType, keyword, page }: Parameters<ILearnPackService['search']>[0]) {
    const { data } = await this.http.get(`/search/contents/${contentType}`, {
      params: {
        page,
        size: 20,
        keyword,
      },
    });
    return {
      content: data.content,
      totalElements: data.totalElements,
      last: data.last,
    };
  }

  async openGraph(link: Parameters<ILearnPackService['openGraph']>[0]) {
    const { data } = await this.http.post('/open-graph', {
      externalLink: link,
    });
    return {
      thumbnail: data.thumbnail,
      link: data.link,
      origin: data.origin,
      title: data.title,
      description: data.description,
    };
  }

  async create({
    title,
    introduction,
    tags,
    learnPackElements,
  }: Parameters<ILearnPackService['create']>[0]) {
    const { data } = await this.http.post('', {
      title,
      introduction,
      tags,
      learnPackElements,
    });
    return data;
  }

  async getDetail(id: Parameters<ILearnPackService['getDetail']>[0]) {
    const { data } = await this.http.get<ReturnType<ILearnPackService['getDetail']>>(`${id}`);
    return data;
  }

  async update({
    learnPackId,
    title,
    introduction,
    tags,
    learnPackElements,
  }: Parameters<ILearnPackService['update']>[0]) {
    const { data } = await this.http.put(`${learnPackId}`, {
      title,
      introduction,
      tags,
      learnPackElements,
    });
    return data;
  }

  async getMyDetail(id: Parameters<ILearnPackService['getMyDetail']>[0]) {
    const { data } = await this.http.get<ReturnType<ILearnPackService['getMyDetail']>>(`my/${id}`);
    return data;
  }

  async getLearnPackList({ page, size }: Parameters<ILearnPackService['getLearnPackList']>[0]) {
    const { data } = await this.http.get<ReturnType<ILearnPackService['getLearnPackList']>>('', {
      params: {
        page,
        size,
      },
    });

    return data;
  }

  async getMyLearnPackList({ page, size }: Parameters<ILearnPackService['getMyLearnPackList']>[0]) {
    const { data } = await this.http.get<ReturnType<ILearnPackService['getMyLearnPackList']>>(
      'my',
      {
        params: {
          page,
          size,
        },
      },
    );

    return data;
  }

  async delete(learnPackId: Parameters<ILearnPackService['delete']>[0]) {
    const { data } = await this.http.delete(`${learnPackId}`);
    return data;
  }
}

export const learnPackSearvice = new LearnPackSearvice('learn-pack');
