import { ref } from '@vue/composition-api';

export const useDevice = () => {
  const isMobile = ref(false);

  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    isMobile.value = true;
  } else {
    isMobile.value = false;
  }

  return {
    isMobile,
  };
};
