import { authSearvice } from '@/services';
import { getToken, removeToken } from '@/utils';
import { VuexModule, Module, getModule, MutationAction, Mutation } from 'vuex-module-decorators';

import { store } from '@/store';

@Module({
  dynamic: true,
  name: 'account',

  store,
})
export class AccountModule extends VuexModule {
  userId = 0;

  nickName = '';

  email = '';

  profileUrl = null;

  role = '';

  get info() {
    return {
      userId: this.userId,
      nickName: this.nickName,
      email: this.email,
      profileUrl: this.profileUrl,
    };
  }

  @Mutation
  logout() {
    this.userId = 0;
    this.nickName = '';
    this.email = '';
    this.profileUrl = null;
    this.role = '';

    removeToken();
  }

  @MutationAction({ mutate: ['userId', 'nickName', 'email', 'role', 'profileUrl'] })
  async setMeta() {
    if (getToken()) {
      const res = await authSearvice.meta();

      return {
        ...res,
      };
    }

    return {
      userId: 0,
      nickName: '',
      email: '',
      profileUrl: null,
      role: '',
    };
  }
}

export const AccountStore = getModule(AccountModule);
