import { HTTPClient } from '@/api/HTTPClient';

export interface IImageUploadService {
  uploadOne(files: FileList): Promise<{
    name: string;
    url: string;
  }>;
}

class ImageUploadService extends HTTPClient implements IImageUploadService {
  async uploadOne(files: Parameters<IImageUploadService['uploadOne']>[0]) {
    const formData = new FormData();
    formData.append('files', files[0]);

    const response = await this.http.post('', formData);

    return response.data;
  }
}

export const imageUploadService = new ImageUploadService('file/upload');
