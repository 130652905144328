import Cookies from 'js-cookie';

const TOKEN_KEY = 'learnmoa-token';
// const DEV_TOKEN = 'learnmoaWillRule';

export function setToken(token: string) {
  Cookies.set(TOKEN_KEY, token, { expires: 30 });
}

export function getToken() {
  const token = Cookies.get(TOKEN_KEY);
  if (token) {
    return Cookies.get(TOKEN_KEY);
  }

  // if (!isProduction()) {
  //   return DEV_TOKEN;
  // }

  return null;
}

export function removeToken() {
  Cookies.remove(TOKEN_KEY);
}
