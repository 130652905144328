import { HTTPClient } from '@/api/HTTPClient';

import { ILecture, ILectureCategory, ILectureOrderByType, ILectureSearchType } from '@/models';

export interface ILectureService {
  getLectures({
    category,
    page,
    size,
    lectureOrderByType,
    lectureSearchType,
    lectureSearchKey,
    priceType,
    recommendedKeyword,
    lectureOrigin,
    tagList,
  }: {
    category: string;
    page: number;
    size: number;
    lectureOrderByType?: string;
    lectureSearchType?: string;
    lectureSearchKey?: string;
    priceType?: string;
    recommendedKeyword?: string;
    lectureOrigin?: string;
    tagList?: string[];
  }): Promise<{
    content: ILecture[];
    totalElements: number;
  }>;

  getCategories(): Promise<ILectureCategory>;

  getOrderByType(): Promise<ILectureOrderByType[]>;

  getSearchType(): Promise<ILectureSearchType[]>;
}

class LectureService extends HTTPClient implements ILectureService {
  async getLectures({
    category,

    ...params
  }: Parameters<ILectureService['getLectures']>[0]) {
    return (await this.http.get(`${category}`, { params })).data;
  }

  async getCategories() {
    return (await this.http.get('categories')).data;
  }

  async getOrderByType() {
    return (await this.http.get('order-by-type')).data;
  }

  async getSearchType() {
    return (await this.http.get('search-type')).data;
  }
}

export const lectureService = new LectureService('lectures');
