import Vue from 'vue';
import Vuex from 'vuex';

// import { AccountModule } from './modules';

Vue.use(Vuex);

// interface IStoreModule {
//   account: AccountModule;
// }

export const store = new Vuex.Store({});
