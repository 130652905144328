import { HTTPClient } from '@/api/HTTPClient';
import { IRecommendKeyword } from '@/models';

export interface IRecommendKeywordService {
  getRecommendkeywords(category: string): Promise<IRecommendKeyword[]>;
}

class RecommendKeywordService extends HTTPClient implements IRecommendKeywordService {
  async getRecommendkeywords(
    category: Parameters<IRecommendKeywordService['getRecommendkeywords']>[0],
  ) {
    const response = await this.http.get<
      ReturnType<IRecommendKeywordService['getRecommendkeywords']>
    >(`${category}`);
    return response.data;
  }
}

export const recommendKeywordService = new RecommendKeywordService('recommended-keywords');
