import { HTTPClient } from '@/api/HTTPClient';
import { IFilter } from '@/models';

export interface IFilterService {
  getFilters(): Promise<{
    filterValueList: IFilter[];
    sortValueList: IFilter[];
  }>;
}

class FilterService extends HTTPClient implements IFilterService {
  async getFilters() {
    const response = await this.http.get<ReturnType<IFilterService['getFilters']>>('');
    return response.data;
  }
}

export const filterService = new FilterService('filter');
