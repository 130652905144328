import { HTTPClient } from '@/api/HTTPClient';

import { ICategory } from '@/models';
import { IBook } from '@/models/book';

export interface IBookService {
  getCategories(): Promise<ICategory[]>;

  getBooks({
    category,
    page,
    size,
    searchKey,
  }: {
    category: string;
    page: number;
    size: number;
    searchKey: string;
  }): Promise<{
    content: IBook[];
    totalElements: number;
  }>;
}

class BookSearvice extends HTTPClient implements IBookService {
  async getCategories() {
    return (await this.http.get('categories')).data;
  }

  async getBooks({ category, ...params }: Parameters<IBookService['getBooks']>[0]) {
    return (await this.http.get(`${category}`, { params })).data;
  }
}

export const bookSearvice = new BookSearvice('books');
