import { HTTPClient } from '@/api/HTTPClient';
import { ITag } from '@/models';

export interface ITagService {
  getTags({
    page,
    tag,
    lectureOrigin,
    recommendedKeyword,
    priceType,
  }: {
    lectureOrigin?: string;
    priceType?: string;
    recommendedKeyword?: string;
    tag: string;
    page: number;
  }): Promise<ITag>;
}

class SkillService extends HTTPClient implements ITagService {
  async getTags({
    page,
    tag,
    lectureOrigin,
    recommendedKeyword,
    priceType,
  }: Parameters<ITagService['getTags']>[0]) {
    return (
      await this.http.get(`${tag}`, {
        params: { page, lectureOrigin, priceType, recommendedKeyword },
      })
    ).data;
  }
}

export const tagService = new SkillService('tags');
