import { getToken } from '@/utils';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { AccountStore } from '../store/modules';

Vue.use(VueRouter);

export const RountName = {
  LectureList: 'lectureList',
  BookList: 'bookList',
  LearnPackList: 'learnPackList',

  Agreement: 'agreement',
  Privacy: 'privacy',

  AuthRegister: 'authRegister',
  AuthLogin: 'authLogin',
  AuthPasswordReset: 'authPasswordReset',
  AuthPasswordEdit: 'authPasswordEdit',
  AuthProfile: 'authProfile',

  Mypage: 'mypage',
  MypageSetting: 'mypageSetting',

  LearnPackRegister: 'learnPackRegister',
  LearnPackEdit: 'learnPackEdit',
  LearnPackDetail: 'learnPackDetail',
};

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: { name: RountName.LectureList },
  },
  {
    path: '/auth',
    component: () => import(/* webpackChunkName: "base-layout" */ '@/layouts/BaseLayout/index.vue'),
    children: [
      {
        path: 'signup',
        name: RountName.AuthRegister,
        meta: {
          title: '회원가입',
        },
        component: () =>
          import(/* webpackChunkName: "auth-register" */ '@/views/auth/register/index.vue'),
      },
      {
        path: 'signin',
        name: RountName.AuthLogin,
        meta: {
          title: '로그인',
        },
        component: () =>
          import(/* webpackChunkName: "auth-login" */ '@/views/auth/login/index.vue'),
        props: ({ query }) => ({
          from: query.from,
        }),
      },
      {
        path: 'password-reset',
        name: RountName.AuthPasswordReset,
        meta: {
          title: '비밀번호 초기화',
        },
        component: () =>
          import(
            /* webpackChunkName: "auth-password-reset" */ '@/views/auth/password-reset/index.vue'
          ),
      },
      {
        path: 'password-edit',
        name: RountName.AuthPasswordEdit,
        meta: {
          title: '비밀번호 변경',
        },
        component: () =>
          import(
            /* webpackChunkName: "auth-password-edit" */ '@/views/auth/password-edit/index.vue'
          ),
      },
      {
        path: 'profile',
        name: RountName.AuthProfile,
        meta: {
          title: '회원정보',
        },
        component: () =>
          import(/* webpackChunkName: "auth-profile" */ '@/views/auth/profile/index.vue'),
      },
    ],
  },
  {
    path: '/mypage',
    component: () => import(/* webpackChunkName: "base-layout" */ '@/layouts/BaseLayout/index.vue'),
    children: [
      {
        path: '',
        name: RountName.Mypage,
        meta: {
          title: '마이페이지',
        },
        component: () => import(/* webpackChunkName: "mypage-index" */ '@/views/mypage/index.vue'),
      },
      {
        path: 'setting',
        name: RountName.MypageSetting,
        meta: {
          title: '마이페이지 목록',
        },
        component: () =>
          import(/* webpackChunkName: "mypage-setting-index" */ '@/views/mypage/setting/index.vue'),
      },
    ],
  },

  {
    path: '',
    component: () => import(/* webpackChunkName: "base-layout" */ '@/layouts/BaseLayout/index.vue'),
    children: [
      {
        path: '',
        component: () =>
          import(/* webpackChunkName: "base-layout" */ '@/layouts/BannerLayout/index.vue'),
        children: [
          {
            path: 'lectures',
            name: RountName.LectureList,
            meta: {
              title: '강의',
            },
            component: () =>
              import(/* webpackChunkName: "lectures-index" */ '@/views/lectures/index.vue'),
          },
          {
            path: 'books',
            name: RountName.BookList,
            meta: {
              title: '도서',
            },
            component: () =>
              import(/* webpackChunkName: "books-index" */ '@/views/books/index.vue'),
          },
          {
            path: 'learn-pack',
            name: RountName.LearnPackList,
            meta: {
              title: '런팩',
            },
            component: () =>
              import(/* webpackChunkName: "learn-pack-index" */ '@/views/learn-pack/index.vue'),
          },
        ],
      },
    ],
  },

  {
    path: '/agreement',
    component: () => import(/* webpackChunkName: "base-layout" */ '@/layouts/BaseLayout/index.vue'),
    children: [
      {
        path: '',
        name: RountName.Agreement,
        meta: {
          title: '서비스 이용약관',
        },
        component: () => import(/* webpackChunkName: "agreement" */ '@/views/agreement/index.vue'),
      },
    ],
  },
  {
    path: '/privacy',
    component: () => import(/* webpackChunkName: "base-layout" */ '@/layouts/BaseLayout/index.vue'),
    children: [
      {
        path: '',
        name: RountName.Privacy,
        meta: {
          title: '개인정보 처리방침',
        },
        component: () => import(/* webpackChunkName: "privacy" */ '@/views/privacy/index.vue'),
      },
    ],
  },

  {
    path: '/learn-pack',
    component: () => import(/* webpackChunkName: "base-layout" */ '@/layouts/BaseLayout/index.vue'),
    children: [
      {
        path: 'register',
        name: RountName.LearnPackRegister,
        meta: {
          title: '런팩 등록',
        },
        component: () =>
          import(
            /* webpackChunkName: "learn-pack-register" */ '@/views/learn-pack/register/index.vue'
          ),
      },
      {
        path: 'edit/:learnPackId',
        name: RountName.LearnPackEdit,
        meta: {
          title: '런팩 수정',
        },
        props: true,
        component: () =>
          import(/* webpackChunkName: "learn-pack-edit" */ '@/views/learn-pack/register/index.vue'),
      },
      {
        path: ':learnPackId',
        name: RountName.LearnPackDetail,
        meta: {
          title: '런팩 상세',
        },
        props: true,
        component: () =>
          import(/* webpackChunkName: "learn-pack-detail" */ '@/views/learn-pack/detail/index.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, _, next) => {
  const permissions = [
    RountName.Mypage,
    RountName.MypageSetting,
    RountName.AuthPasswordEdit,
    RountName.AuthProfile,
  ];

  if (!getToken()) {
    if (permissions.includes(to.name as string)) {
      next('/');
      return;
    }
  }

  if (getToken()) {
    await AccountStore.setMeta();
    next();
    return;
  }

  next();
});

export default router;
